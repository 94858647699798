export class Shape {
  constructor(mesh) {
    this.mesh = mesh;
  }

  animate() {
  }

  addToScene(scene, callback) {
    scene.add(this.mesh);
    callback();
  }
}
