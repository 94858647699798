import * as THREE from '../node_modules/three';
import {Star} from "./shapes/Star";
import gsap from '../node_modules/gsap';
import ScrollTrigger from "../node_modules/gsap/ScrollTrigger";


gsap.registerPlugin(ScrollTrigger);


let move = false;

const smallDevice = window.matchMedia("(max-width: 620px)");

function createLights() {
  var light = new THREE.DirectionalLight(0xff11ff);
  var light2 = new THREE.AmbientLight(0xffffff);

  light.position.set(1.5, 1, 1).normalize();
  light2.position.set(2, 1, 1).normalize();

  return [light2, light];
}

function getCamera() {
  const container = document.getElementById("canvas-container");
  const height = container.offsetHeight;
  const width = container.offsetWidth;

  const camera = new THREE.PerspectiveCamera(
    45,
    width / height,
    0.001,
    1000
  );
  camera.position.set(0, 0, 6.3);

  return camera;
}

class Scene {
  constructor({lights, shapes, camera}) {
    this.renderer = new THREE.WebGLRenderer({
      canvas: document.getElementById("c"),
       antialias: true,
      alpha: true,
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.container = document.getElementById("canvas-container");
    this.height = this.container.offsetHeight;
    this.width = this.container.offsetWidth;
    this.renderer.setSize(this.width, this.height);
    this.renderer.setClearColor(0x171017, 0);

    this.camera = camera;

    this.target = new THREE.Vector2();
    this.windowHalf = new THREE.Vector2(window.innerWidth / 2, window.innerHeight / 2);

    this.tanFOV = Math.tan(((Math.PI / 180) * this.camera.fov / 2));
    this.windowHeight = this.container.offsetHeight;

    this.scene = new THREE.Scene();

    this.lights = [...lights];
    this.lights.forEach((light) => this.scene.add(light));
    this.shapes = [...shapes];

    this.addEvents();
        this.shapes.forEach((shape) => shape.addToScene(this.scene, () => {
    requestAnimationFrame((timestamp) => this.animate_x(timestamp));
    }))
  }

  addEvents() {
    window.addEventListener("resize", this.resize.bind(this));
    document.addEventListener('mousemove', this.onMouseMove.bind(this));
    window.addEventListener('deviceorientation', this.onDeviceMove.bind(this));
     document.addEventListener('scroll', this.onScroll.bind(this));
  }

  onScroll(){
    move = true;
    requestAnimationFrame((timestamp) => this.animate_x(timestamp));
  }

  onMouseMove(e) {
    const x = (e.clientX - this.windowHalf.x);
    const y = (e.clientY - this.windowHalf.y);

    this.target.x = (1 - x) * 0.002;
    this.target.y = (1 - y) * 0.002;

    move = true;
    requestAnimationFrame((timestamp) => this.animate_x(timestamp));
  }

  onDeviceMove(e) {
    move = true;
    let y = (e.gamma);

    this.target.y = y ? y * (Math.PI / 180) : 0;
  }

  resize() {
    this.windowHalf.set(this.container.offsetWidth / 2, this.container.offsetHeight / 2);
    this.renderer.setSize(this.container.offsetWidth, this.container.offsetHeight);
    //this.composer.setSize(this.container.offsetWidth, this.container.offsetHeight);
    this.camera.aspect = this.container.offsetWidth / this.container.offsetHeight;

    // adjust the FOV
    this.camera.fov = (360 / Math.PI) * Math.atan(this.tanFOV * (this.container.offsetHeight / this.windowHeight));

    this.camera.lookAt(this.scene.position);
    this.camera.updateProjectionMatrix();
  }

  animate_x(timestamp) {
    if (this.timestamp === timestamp) {
      return;
    }
    this.timestamp = timestamp;

    if(move === true){
      requestAnimationFrame((timestamp) => this.animate_x(timestamp));
    }

    this.render();

    this.shapes.forEach((shape) => shape.animate(this.target, this.camera));
  }

  render() {
    this.scene.traverse(function (child) {
      if (child.isMesh) {
        const shader = child.material.userData.shader;

        if (shader) {
          shader.uniforms.time.value = performance.now() * 0.0008;
        }
      }
    });

    this.renderer.render(this.scene, this.camera);
  }
}


setTimeout(
  () => {

  const camera = getCamera();

  new Scene({
    lights: createLights(),
    shapes: [
      new Star({camera})],
    camera
  })

},
  0
)



gsap.to(".napis", {repeat: -1, duration: 10, ease: "none", paused: false, rotation: 360, transformOrigin: "50% 50%"});
const section = document.querySelectorAll('.hero-section-text > h1');

const texts = document.querySelectorAll('.letter');


// texts.forEach((text) =>{
let tm = gsap.timeline({
  scrollTrigger: {
    trigger: "#hero-section",
    start: 'center bottom',
    end: '+=100 top',
    //markers: true,
    // toggleActions: 'play reverse play reset',
  }
});
tm.from('.hero-section-text > h1 > .animation > .desc', {
  duration: 2,
  yPercent: 200,
  ease: "power4",
  skewY: 15,
  stagger: 0.1,
  opacity: 0,
}, 0).to(('.hero-section-text > h1 > .animation'), {
  overflow: 'visible',
}, 1);


let hero = gsap.timeline({

  scrollTrigger: {
    trigger: ".hero-section",
    start: "top+100 top",
    end: "bottom top",
    // markers: true,
    toggleActions: 'play pause play reverse',
    scrub: 1,
  },

});


let aboutUs = gsap.timeline({
  scrollTrigger: {
    trigger: ".hero-section",
    endTrigger: ".aboutus-section",
    start: "center top",
    end: "center top",
    toggleActions: 'play reverse play reverse',
    scrub: 1,
  },
});


let services = gsap.timeline({
  scrollTrigger: {
    trigger: ".aboutus-section",
    start: "center top",
    endTrigger: ".services-section",
    end: "center top",
    scrub: 1,
    toggleActions: 'play pause play reverse',
  },

});


let realizations = gsap.timeline({
  scrollTrigger: {
    trigger: ".services-section",
    start: "center top",
    endTrigger: ".realizations-section",
    end: "center top",
    scrub: 1,
    toggleActions: 'play pause play reverse',
  },

});


let contact = gsap.timeline({
  scrollTrigger: {
    trigger: ".realizations-section",
    start: "center top",
    endTrigger: ".contactus-section",
    end: "top top",
    scrub: 1,
    toggleActions: 'play pause play reverse',
  },
});


function handleDeviceChangeAll(e) {
  if (e.matches) {
    //hero
    hero.to(".hero-section-text > h1 > .animation >.one", {
      x: '2.94vw'
    }, 0).to(".hero-section-text > h1 > .animation >.two", {
      x: '-5.88vw',
    }, 0).to(".hero-section-text > h1 > .animation >.four", {
      x: '3.94vw',
    }, 0);

    //aboutus
    aboutUs.from(".aboutus-section-text > h1 > .animation >.one", {
      x: '2.88vw',
    }, 0).from(".aboutus-section-text > h1 > .animation >.two", {
      x: '-2.88vw',
    }, 0);
    //services
    services.from(".services-section-text > h1 > .animation >.one", {
      x: '1.88vw',
    }, 0).from(".services-section-text > h1 > .animation >.two", {
      x: '-1.88vw',
    }, 0);
    //realizations
    realizations.fromTo(".realizations-section-text-body-left", {
      y: '7.88rem',
    }, {y: '3.88rem'}, 0).from(".realizations-section-text > h1 > .animation >.one", {
      x: '2.35vw',
    }, 0);

  } else {
    //hero
    hero.to(".hero-section-text > h1 > .animation >.one", {
      x: '2.94rem',
    }, 0).to(".hero-section-text > h1 > .animation >.two", {
      x: '5.88rem',
    }, 0).to(".hero-section-text > h1 > .animation >.three", {
      x: '2.94rem',
    }, 0).to(".hero-section-text > h1 > .animation >.four", {
      x: '11.76rem',
    }, 0);

    //aboutus

    aboutUs.fromTo(".aboutus-section-text > h1 > .animation >.one", {
      x: '5.88rem',
      y: 0,
    }, {x: 0, y: '-2.94rem'}, 0).fromTo(".aboutus-section-text > h1 > .animation >.two", {
      x: '-5.88rem',
      y: 0,
    }, {x: 0, y: '-2.94rem'}, 0);

    //services
    services.from(".services-section-text > h1 > .animation >.one", {
      x: '5.88rem',
    }, 0).from(".services-section-text > h1 > .animation >.two", {
      x: '-5.88rem',
    }, 0);

    //realizations
    realizations.from(".realizations-section-text > h1 > .animation >.one", {
      y: '-2.35rem',
    }, 0).to(".realizations-section-text > h1 > .animation >.two", {
      x: '' +
        '17.64rem',
    }, 0).from(".realizations-section-text-body-left", {
      y: '5.88rem',
    }, 0);

    //contact
    contact.from(".contactus-section-text > div > h1 > .animation >.one", {
      x: '5.88rem',
    }, 0).to(".contactus-section-text > div > h1 > .animation >.two", {
      x: '20%',
    }, 0);
  }
}

smallDevice.addEventListener('change', handleDeviceChangeAll);
handleDeviceChangeAll(smallDevice);


var letters = document.querySelectorAll('.nav-link > span > a > span');
letters.forEach(entry => {
  entry.innerHTML = entry.textContent.replace(/\S/g, "<span class='one-letter'>$&</span>");
})


document.querySelectorAll('.nav-link > span > a').forEach(entry => {

  var lettersTl = gsap.timeline({paused: true});
  lettersTl.to(entry.querySelectorAll(".first-word >.one-letter"), {
    yPercent: -100,
    rotateX: -90,
    transformOrigin: 'bottom',
    stagger: {
      each: 0.02,
    }
  }, 0).fromTo(entry.querySelectorAll(".second-word >.one-letter"), {
    yPercent: 100,
    rotateX: -90,
    transformOrigin: 'top',
    stagger: {
      each: 0.02,
    }
  }, {
    yPercent: 0,
    rotateX: 0,
    transformOrigin: 'top',
    stagger: {
      each: 0.02,
    }
  }, 0);
  entry.addEventListener('mouseenter', () => {
    lettersTl.play();
  })
  entry.addEventListener('mouseleave', () => {
    lettersTl.reverse();

  })
});

var letters2 = document.querySelectorAll('.mobile-nav-link > span > a > span');
letters2.forEach(entry => {
  entry.innerHTML = entry.textContent.replace(/\S/g, "<span class='one-letter'>$&</span>");
})


document.querySelectorAll('.mobile-nav-link > span > a').forEach(entry => {

  var lettersTl2 = gsap.timeline({paused: true});
  lettersTl2.to(entry.querySelectorAll(".first-word >.one-letter"), {
    yPercent: -100,
    rotateX: -90,
    transformOrigin: 'bottom',
    stagger: {
      each: 0.02,
    }
  }, 0).fromTo(entry.querySelectorAll(".second-word >.one-letter"), {
    yPercent: 100,
    rotateX: -90,
    transformOrigin: 'top',
    stagger: {
      each: 0.02,
    }
  }, {
    yPercent: 0,
    rotateX: 0,
    transformOrigin: 'top',
    stagger: {
      each: 0.02,
    }
  }, 0);
  entry.addEventListener('mouseenter', () => {
    lettersTl2.play();
  })
  entry.addEventListener('mouseleave', () => {
    lettersTl2.reverse();

  })
});

//eyemove

let eyeBall = document.querySelector(".eyeball"),
  pupil = document.querySelector(".pupil"),
  eyeArea = eyeBall.getBoundingClientRect(),
  pupilArea = pupil.getBoundingClientRect(),
  R = 115,
  centerX = eyeArea.left + R,
  centerY = eyeArea.top + R;


document.addEventListener("scroll", () => {
  const eyeArea = eyeBall.getBoundingClientRect();
  centerX = eyeArea.x;
  centerY = eyeArea.y;
});


document.addEventListener("mousemove", (e) => {
  let x = e.clientX - centerX,
    y = e.clientY - centerY,
    theta = Math.atan2(y, x),
    angle = theta + Math.PI / 2;  // Account for rotation WRT euclidean plane

  const eye_r = 4.5;

  const maxOutXAt = Math.max(pupilArea.left, pupilArea.right);
  const maxOutYAt = Math.max(pupilArea.top, pupilArea.bottom);

  const clamp = (number, to) => {
    if (number < to) return number;
    return to;
  }

  let ratioX = clamp(Math.abs(x / maxOutXAt), 1);
  let ratioY = clamp(Math.abs(y / maxOutYAt), 1);


  const ratioTransform = (ratio) => -Math.sqrt(ratio);
  const dy = eye_r * Math.cos(angle);
  const dx = -eye_r * Math.sin(angle);


  const shiftY = ratioTransform(ratioY) * dy;  // account for flipped Y axis
  const shiftX = ratioTransform(ratioX) * dx;

  pupil.style.transform = `translateY(${shiftY}px) translateX(${shiftX}px)`;
});

//burger menu
const menuBtn = document.querySelector('.burger-menu-btn');
const menuOverlay = document.querySelector('.overlay-menu');
let menuOpen = false;

menuBtn.addEventListener('click', () => {
  if (!menuOpen) {
    menuBtn.classList.add('is-active');
    menuOverlay.classList.add('open-overlay');
    menuOpen = true;
  } else {
    menuBtn.classList.remove('is-active');
    menuOverlay.classList.remove('open-overlay');

    menuOpen = false;
  }
});

document.querySelectorAll('.overlay-menu > .overlay-menu-inner > ul li span a').forEach((e) => {
  e.addEventListener("click", () => {
    menuBtn.classList.remove('is-active');
    menuOverlay.classList.remove('open-overlay');
    menuOpen = false;
  })
});

const showAnim = gsap.from('.site-header', {
  yPercent: -100,
  paused: true,
  duration: 0.2
}).progress(1);

ScrollTrigger.create({
  start: "top top",
  end: 99999,
  onUpdate: (self) => {
    if (menuOpen) {
      showAnim.play();
    } else {
      self.direction === -1 ? showAnim.play() : showAnim.reverse()
    }
  }
});

let burger = gsap.timeline({
  scrollTrigger: {
    trigger: ".hero-section",
    start: "+100 top",
    end: "+150 top",
    toggleActions: 'play none play reverse',
  },

});

burger.to(".site-header", {opacity: 1});


let currentScroll = 0;
let isScrollingDown = true;

let tween = gsap.to(".anim", {xPercent: -100, repeat: -1, duration: 8, ease: "linear"}).totalProgress(0.5);

gsap.set(".services-big-text", {xPercent: -50});

window.addEventListener("scroll", function () {

  if (window.pageYOffset > currentScroll) {
    isScrollingDown = true;
  } else {
    isScrollingDown = false;
  }

  gsap.to(tween, {
    timeScale: isScrollingDown ? 1 : -1
  });

  currentScroll = window.pageYOffset
});

