import {Shape} from "./shape";
import * as THREE from "three";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import model from "../model/source/statr2.glb";
import gsap from "gsap";

import px from "../model/textures/lights/px.webp";
import nx from "../model/textures/lights/nx.webp";
import py from "../model/textures/lights/py.webp";
import ny from "../model/textures/lights/ny.webp";
import pz from "../model/textures/lights/pz.webp";
import nz from "../model/textures/lights/nz.webp";


function animateStar(star, camera) {
  const smallDevice = window.matchMedia("(max-width: 620px)");

  let heroStar = gsap.timeline({
    scrollTrigger: {
      trigger: ".hero-section",
      endTrigger: ".realizations-section",
      start: "100 top",
      end: "center top",
      // markers: true,
      toggleActions: 'play reverse play reverse',
      scrub: 1,
      fastScrollEnd: true,
      // preventOverlaps: true,
    },
  });

  function handleDeviceChange(e) {
    if (e.matches) {
      camera.position.z = 11.3;
      star.position.x = 0;
      star.position.y = 0;

      star.rotation.x = .15;
      heroStar.to(star.position, {y: 3, x: -1}, 0).to(star.scale, {x: 0.8, y: 0.8, z: 0.8}, 0)
        .to(star.rotation, {x: 6, duration: 4}, 1)
        .to(star.position, {y: 7,}, 2).to(star.scale, {x: 0.4, y: 0.4, z: 0.4}, 2)
        .to(star.position, {x: -3}, 3)
        .to(star.position, {y: 2, x: -7}, 4)
        .to(star.position, {y: 1}, 5)
        .to(star.position, {y: 1, x: -2}, 6).to(star.rotation, {x: 3, duration: 4}, 6)
        .to(star.position, {y: 0, x: 1}, 7).to(star.scale, {x: 0.9, y: 0.9, z: 0.9}, 7)
      ;

    } else {
      star.position.y = -2;
      star.position.x = 0.5;

      heroStar.to(star.position, {y: 1, x: -1}, 0).to(star.scale, {x: 0.8, y: 0.8, z: 0.8}, 0)
        .to(star.rotation, {x: 6, duration: 4}, 1)
        .to(star.position, {y: 4,}, 2).to(star.scale, {x: 0.4, y: 0.4, z: 0.4}, 2)
        .to(star.position, {x: -7}, 3)
        .to(star.position, {y: 2, x: -7}, 4)
        .to(star.position, {y: 1}, 5)
        .to(star.position, {y: 1, x: -2}, 6).to(star.rotation, {x: 3, duration: 4}, 6)
        .to(star.position, {y: 0, x: 2}, 7).to(star.scale, {x: 0.9, y: 0.9, z: 0.9}, 7)
      ;
    }
  }

  smallDevice.addEventListener('change', handleDeviceChange);
  handleDeviceChange(smallDevice);


}


function stepper(steps, callback)
{
  let stepCount = 0;
  return () => {
    stepCount += 1;
    if (stepCount === steps) callback();
  }
}

export class Star extends Shape {
  constructor({camera}) {
    super(null);


    this.load = new Promise((resolve) => {
      const step = stepper(2, resolve);

     var textureCube = new THREE.CubeTextureLoader()
      .load([
        px,
        nx,
        py,
        ny,
        pz,
        nz,
      ], step);

      // var textureCube = new THREE.CubeTextureLoader().load(urls, step);
      textureCube.format = THREE.RGBAFormat;
// textureCube.mapping = THREE.EquirectangularReflectionMapping;
      this.loader = new GLTFLoader();
      this.loader.load(model, (gltf) => {
          gltf.scene.traverse(o => {
            if (o.isMesh) {

              o.scale.set(165, 25, 165);
              o.geometry.center();
              o.rotation.x = -0.15;
              o.rotation.z = 0.35;
              //o.position.set(0,-4,0);

              o.material = new THREE.MeshLambertMaterial({
                // vertexColors: THREE.VertexColors,
                color: 0xff11ff,
                emissive: 0x000,
                envMap: textureCube,
                //roughness: 0,
                //metalness: 0.8,
                reflectivity: 0.8,
                //opacity: 1,
                //envMapIntensity: 5.0,
                side: THREE.DoubleSide,
              });

              // o.material = new THREE.MeshPhongMaterial({
              //   // vertexColors: THREE.VertexColors,
              //   color: 0xff11ff,
              //   emissive: 0x000,
              //   specular: 0xffffff,
              //   envMap: textureCube,
              //   //roughness: 0,
              //   //metalness: 0.8,
              //   shininess: 50,
              //   reflectivity: 0.8,
              //   //opacity: 1,
              //   //envMapIntensity: 5.0,
              //   refractionRatio: 1,
              //   side: THREE.DoubleSide,
              // });
            }
          })
          this.mesh = gltf.scene;
          const star = gltf.scene;

          star.position.y = 0;
          star.position.x = -2.2;

          animateStar(star, camera);

          step();
        }
      )
    });
  }

  animate(target, camera) {
    if (this.mesh == null)
      return;

    this.mesh.rotation.z = 0.1 * (target.x - camera.rotation.y);
    this.mesh.rotation.y = 0.4 * (target.y - camera.rotation.x);
    // this.mesh.rotation.y += 0.002;

  }

  addToScene(scene, callback) {
    this.load.then(
      () => {
        scene.add(this.mesh);
        callback();

        callback();
      }
    )
  }
}
